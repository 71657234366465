import { CiHeart } from 'react-icons/ci';
import { LiaBombSolid } from 'react-icons/lia';
import { PiWarningDiamond } from 'react-icons/pi';
import { GoUnverified } from 'react-icons/go';
import EyesIcon from 'components/icons/EyesIcon';
import { t } from 'core/resources/strings';
export interface FilterType {
  [key: string]: {
    value: any;
    label: string;
  }[];
}
export interface FilterProps {
  onChangeFilters: (value: any) => void;
  filters: {
    [key: string]: {
      value: any;
      label: string;
    }[];
  };
}
export const safeSpaceFlags = [
  {
    label: t('app.naoClassificado'),
    bg: '#F5F5F5',
    color: '#7B7B7B',
    icon: GoUnverified,
    value: 0,
  },
  {
    label: t('app.coracaoQuentinho'),
    bg: '#CEEACF',
    color: '#336732',
    icon: CiHeart,
    value: 1,
  },
  {
    label: t('app.bomFicarDeOlho'),
    bg: '#B2EBFE',
    color: '#0067AA',
    icon: EyesIcon,
    value: 2,
  },
  {
    label: t('app.redobreAtencao'),
    bg: '#FFE4A9',
    color: '#8A4C00',
    icon: PiWarningDiamond,
    value: 3,
  },
  {
    label: t('app.problemaSerio'),
    bg: '#FFCDD3',
    color: '#BB191E',
    icon: LiaBombSolid,
    value: 4,
  },
];

export interface ComparativeItem {
  type: 'student' | 'class';
  id: string;
  name: string;
}