import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row } from 'antd';
import ClockIcon from 'components/icons/ClockIcon';
import ResourcesIcon from 'components/icons/ResoucesIcon';
import Star from 'components/star';
import usePractice from 'core/features/practices/hooks/usePractice';
import { t } from 'core/resources/strings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { forwardRef } from 'react';
import { GoAlertFill } from 'react-icons/go';
import { PiPencilThin } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import './style.css';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useDeletePracticeAdaptation } from 'core/features/practices/hooks/useDeletePracticeAdaptation';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
const moment = require('moment');
require('moment/locale/pt-br');


const Practice = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    colorPrimary,
    colorGreyBorderAndFont,
    colorSecondary,
    colorPrimaryMedium,
  } = useU4heroColors();
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams<{ id: string }>();
  const { data, refetch } = usePractice(params.id);
  const createdAt = moment(data?.createdAt);
  const { mutate: handleDelete} =
    useDeletePracticeAdaptation();

  const onDelete = (id: string) => {
    showConfirm({
      message: t('app.temCertezaQueDesejaDesfazerAsAlteracoes'),
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(id, {
          onSuccess: () => {
            notificateSucess(t('app.alteracoesDesfeitasComSucesso'));
            refetch();
          },
          onError: () => {
            notificateError(t('app.erroAoDesfazerAlteracoes'));
          },
        });
      }
    });
  };
  return (
    <div ref={ref} className="practice-container">
      <div style={{ position: 'relative' }}>
        <img
          alt={`banner ${data?.title ?? ''}`}
          src={data?.banner}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '275px',
            objectFit: 'cover',
          }}
        />
        {!isMobile ? (
          <div style={{ position: 'absolute', bottom: -10, left: -20 }}>
            <Star color={colorPrimary} size={'70px'} fontSize="16px">
              {t(`app.${data?.analysisEnvironment.name}`)}
            </Star>
          </div>
        ) : (
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorPrimary,
              color: 'white',
              top: 0,
            }}
          >
            {t(`app.${data?.analysisEnvironment.name}`)}
          </div>
        )}

        <Flex
          style={{
            width: '100%',
            position: 'relative',
            bottom: isMobile ? 18 : 10,
          }}
          justify="center"
        >
          <div
            style={{
              borderRadius: '5px 5px 0px 0px',
              fontSize: isMobile ? '12px' : '16px',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorSecondary,
              fontWeight: 700,
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            {data?.target} - {t(`app.${data?.practiceModality.name}`)}
          </div>
        </Flex>
      </div>

      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          color: colorGreyBorderAndFont,
          textAlign: 'end',
        }}
      >
        {createdAt.format('DD [de] MMMM [de] YYYY')}
        <br />
        {t('app.autor')}: {data?.author}
      </p>
      {!isMobile ? (
        <SubTitle
          align="center"
          primary
          style={{
            marginBottom: '8px',
          }}
        >
          {data?.title}
        </SubTitle>
      ) : null}

      <Row gutter={[24, 0]}>
        <Col span={isMobile ? 24 : 3}>
          <Flex vertical gap={isMobile ? '8px':'40px'}>
          <Flex
            vertical={!isMobile}
            justify={isMobile ? 'flex-start' : 'center'}
            align="center"
            gap="8px"
          >
            <ClockIcon size={isMobile ? 20 : 36} color={colorPrimary} />
            <p
              style={{
                fontSize: isMobile ? 14 : 16,
                color: colorPrimary,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              {t('app.duracao')}:{' '}
              <span
                style={{
                  color: 'black',
                  fontWeight: 400,
                }}
              >
                {data?.duration} {t('app.minutos')}
              </span>
            </p>
          </Flex>
          <Flex
            vertical={!isMobile}
            justify={isMobile ? 'flex-start' : 'center'}
            align="center"
            gap="8px"
          >
               <Flex style={{width:isMobile ? '20px':'36px',height:isMobile ? '20px':'36px'}}>
            <ResourcesIcon size={isMobile ? 20 : 36} color={colorPrimary} />
               </Flex>
            <p
              style={{
                fontSize: isMobile ? 14 : 16,
                color: colorPrimary,
                fontWeight: 600,
                textAlign: isMobile ? 'start' : 'center',
              }}
            >
              {t('app.recursos')}:
              <span
                style={{
                  color: 'black',
                  fontWeight: 400,
                }}
              >
                {' '}
                {data?.adaptation
                  ? data?.adaptation?.resources
                  : data?.resources}
              </span>
            </p>
          </Flex>
          </Flex>
          {isMobile ? (
            <SubTitle
              primary
              align="center"
              style={{
                marginBottom: '8px',
                marginTop: '4px',
              }}
            >
              {data?.title}
            </SubTitle>
          ) : null}
        </Col>
        <Col span={isMobile ? 24 : 21}>
          <Flex
            vertical
            gap="8px"
            style={{ marginBottom: isMobile ? '16px' : '24px' }}
          >
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                {t('app.competencia')}:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {data?.competence1.name['pt-br']}{' '}
                  {data?.competence2?.name['pt-br']
                    ? `e ${data.competence2.name['pt-br']}`
                    : ''}
                </span>
              </p>
            </Flex>
            {data?.skill?.name['pt-br'] ? (
              <Flex>
                <p
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    color: colorPrimary,
                    fontWeight: 600,
                  }}
                >
                  {t('app.habilidade')}:{' '}
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 400,
                    }}
                  >
                    {data?.skill?.name['pt-br']}
                  </span>
                </p>
              </Flex>
            ) : null}

            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                {t('app.faixaEtaria')}:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {data?.maxAge} {t('app.anosMais')} 
                </span>
              </p>
            </Flex>
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                {t('app.disciplinaRelacionada')}:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {data?.subject}
                </span>
              </p>
            </Flex>
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                {t('app.objetivo')}:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {data?.goal}
                </span>
              </p>
            </Flex>
          </Flex>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.adaptation
                ? data.adaptation.content
                : data?.content ?? '',
            }}
          />
        </Col>
      </Row>
      {check([
        Permissions.U4hero.Practices.Adapt,
        Permissions.Practices.Adapt,
      ]) ? (
        <Flex
          vertical
          gap="16px"
          style={{ marginBottom: '16px', marginTop: '16px' }}
          className="adapt"
        >
          <Flex
            justify="space-between"
            align="center"
            style={{
              padding: '24px 16px',
              background: hex2rgba(colorPrimaryMedium, 0.4),
              borderRadius: '16px',
            }}
          >
            <Flex gap={'16px'} align="center">
              <PiPencilThin size={isMobile ? 38 : 50} color="white" />
              <p
                style={{
                  color: 'white',
                  fontWeight: 700,
                  fontSize: isMobile ? '14px' : '24px',
                  maxWidth: isMobile ? '112px' : undefined,
                }}
              >
                {t('app.personalizeEssaPraticaDeAcordoComSeuContexto')}
              </p>
            </Flex>
            <Button
              size="md"
              onClick={() => history.push(`/practices/adapt/${params.id}`)}
              id="id-tour-practices-button-adapt"

            >
              {t('app.adaptarInspiracao')}
            </Button>
          </Flex>
          {data?.adaptation && (
            <Flex gap={8} align="center">
              <GoAlertFill color={colorGreyBorderAndFont} size={24} />
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorGreyBorderAndFont,
                }}
              >
                {t('app.essaPraticaPedagogicaFoiAlterada')},{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                  onClick={() => onDelete(data.adaptation?.id as string)}
                >
                  {t('app.cliqueAqui')}
                </span>{' '}
                {t('app.seDesejaDesfazerAsAlteracoesEDesfazerParaAPraticaOriginal')}
              </p>
            </Flex>
          )}
        </Flex>
      ) : null}
    </div>
  );
});

export default Practice;
