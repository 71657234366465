import { translate } from '../polyglot';

export default translate('es', {
  app: {
    idadeMinima: 'Edad mínima',
    idadeMaxima: 'Edad máxima',
    genero: 'Género',
    tempoMedio: 'Tiempo medio',
    objetivoLudico: 'Propósito Lúdico',
    objetivoPedagogico: 'Objetivo pedagógico',
    competencias: 'Competencias',
    questoes: 'Preguntas',
    conteudos: 'Contenido',
    conteudo: 'Contenido',
    remover: 'Para eliminar',
    emocionometro: 'Emocionómetro',
    indicadores_gerais: 'Indicadores Gerais',
    indicadores_avancados: 'Indicadores avanzados',
    rankJogadores: 'Rango de jugador',
    detalhes: 'Detalles',
    modelo: 'Modelo',
    cancelar: 'Cancelar',
    importar: 'Importar',
    importacao: 'Importar',
    ver_mais: 'Ver más',
    historico: 'Histórico',
    recomendacao: 'Recomendación',
    adicionar: 'Para agregar',
    maximo: 'Máximo',
    minimo: 'Mínimo',
    escola: 'Escuela',
    escolas: 'Escuelas',
    estudantes: 'estudiantes',
    estudante: 'estudiante',
    serie: 'Serie',
    series: 'Series',
    turma: 'Clase',
    turmas: 'Clases',
    imagem: 'Imagem',
    filtro: 'Filtrar',
    filtrar: 'Filtrar',
    imprimir: 'Imprimir',
    enviarEmail: 'Enviar',
    comentarios: 'Comentarios',
    comentar: 'Comentario',
    colaboradores: 'Colaboradores',
    colaborador: 'Colaborator',
    graficos: 'Gráficos',
    grafico_geral: 'Gráficos generales',
    sair: 'Fuera',
    configuracoes: 'Ajustes',
    nome: 'Nombre',
    opcoes: 'Opciones',
    editar: 'Editar',
    desativar: 'Desactivar',
    ativar: 'Activar',
    ativo: 'Activo',
    inativo: 'Inactivo',
    aviso: 'Advertencia',
    sucesso: 'Éxito',
    erro: 'Error',
    portugues: 'Portugués',
    ingles: 'Inglés',
    espanhol: 'Español',
    criar: 'Crear',
    entrar: 'iniciar sesión',
    facaBusca: 'Buscar',
    voltar: 'Vuelve',
    salvar: 'Guardar',
    documento: 'Documento',
    telefone: 'Teléfono',
    site: 'Site',
    emailAdmin: 'Correo electrónico del director',
    nomeAdmin: 'Nombre del director',
    pais: 'País',
    endereco: 'Direccíon',
    adicionarTurma: 'Agregar clase',
    cidade: 'Ciudad',
    bairro: 'Barrio',
    estado: 'Estado',
    rua: 'Calle',
    complemento: 'Complemento',
    numero: 'Número',
    cep: 'Código Postal',
    matutino: 'Mañana',
    vespertino: 'Noche',
    noturno: 'Nocturno',
    periodo: 'Período',
    excluir: 'Eliminar',
    perfil: 'Perfil',
    usuario: 'Usuário',
    senha: 'Contraseña',
    acessoTurmas: 'Acceso a clases',
    idade: 'Edad',
    anos: 'Años',
    dataNascimento: 'Fecha de nacimiento',
    nomePai: 'Nombre del Padre',
    nomeMae: 'Nombre de la madre',
    limpar: 'Limpiar',
    aplicar: 'Aplicar',
    relatorio: 'Reporte',
    relatorios: 'Reportes',
    relatorioCompleto: 'Reporte completo',
    sexo: 'Sexo',
    feminino: 'Feminino',
    masculino: 'Masculino',
    esqueciSenha: 'Olvide mi contraseña',
    voltarParaLogin: 'Atrás para iniciar sesión',
    recuperarSenha: 'Recuperar contraseña',
    jogos: 'Juegos',
    jogo: 'Juego',
    aventura: 'Aventuras',
    exploracao: 'Exploración',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Capítulo',
    capitulos: 'Capítulos',
    tipo: 'Tipo',
    perguntas: 'Preguntas',
    licenca: 'Licencia',
    descricao: 'Descripción',
    hipotese: 'hipótesis',
    orientacao: 'Orientación',
    resposta: 'Responder',
    pergunta: 'Pregunta',
    tag: 'Tag',
    tag_pai: 'Tag padre',
    jogoEmocoes: 'Juego de emociones',
    graficoEmocoes: 'Gráfico de emociones',

    autoGestao: 'Autogestion',
    autoconsciencia: 'Autoconciencia',
    habilidadesRelacionamento: 'Habilidades de relación',
    conscienciaSocial: 'Conciencia Social',
    tomadaDecisaoResponsavel: 'Decisión Responsable',
    graficoSocioemocional: 'Gráfico Socioemocional',
    socioemocional: 'socioemocional',

    nivemCompreensao: 'Nivel de comprensión',
    otimaCompreensao: 'Gran entendimiento',
    boaCompreensao: 'Buena comprensión',
    razoavelCompreensao: 'Comprensión razonable',
    poucaCompreensao: 'Poca comprensión',
    graficoBullying: 'Bullying gráfico',

    memoriaTrabalho: 'Memoria de trabajo',
    flexibilidadeCognitiva: 'Flexibilidad cognitiva',
    controleInibitorio: 'Control inhibitorio',
    legenda: 'Subtitular',
    baixoControleInibitorio: 'Control inhibitorio bajo',
    medioControleInibitorio: 'Control inhibitorio medio',
    altoControleInibitorio: 'Alto control inhibitorio',

    entendimentoTurma: 'Comprensión de clase',
    entendimentoSerie: 'Entendiendo la serie',
    entendimentoAluno: 'Comprensión del estudiante',

    graficoFuncoesExecutivas: 'Gráfico de Funciones Ejecutivas',
    mediaCliques10Segundos: 'Clics promedio cada 10 segundos',

    acimaDe20: 'Por encima de 20',
    de15a20: 'de 15 a 20',
    ate10: 'Hasta 10',

    graficoHumor: 'Gráfico de estado de ánimo',
    nivelCompreensao: 'Nivel de comprensión',

    'Estou feliz': 'Estoy feliz',
    'Estou com medo': 'Estoy asustado',
    'Estou triste': 'Estoy triste',
    'Estou com raiva': 'Estoy enojado',
    'Estou com nojo': 'Estoy disgustado',

    'Estou feliz.': 'Feliz',
    'Estou com medo.': 'Miedo',
    'Estou triste.': 'Triste',
    'Estou com raiva.': 'Enojado',
    'Estou com nojo.': 'Asco',

    configuracao: 'Configuración',
    editar_perfil: 'Editar perfil',
    inicial: 'Inicial',
    encerramento: 'Clausura',
    investigacao: 'Investigación',

    Admin: 'Administrador(a)',
    Teacher: 'Profesor',

    morning: 'Mañana',
    evening: 'Noche',
    afternoon: 'Vespertino',
    nocturnal: 'Nocturno',
    organizacao: 'Organización',
    ola: 'Hola',
    estudantesAtivos: 'Estudiantes activos esta semana',
    confiraPainelEstudante: 'Confira el panel del estudiante',
    conhecaHistoria: 'Conoce la historia de',
    leiaAgora: 'Leer ahora',
    conferir: 'Verificar',
    fiquePorDentro: 'Manténgase actualizado con las noticias de U4Hero',
    saibaMais: 'Más información',
    rotoriLore:
      'En un bosque mágico llamado Bosque Flickering, había una criatura extraordinaria conocida como Rotori. Parecía un oso, pero sus características especiales lo hacían único en todo el valle. Tenía un pelaje suave, de color marrón claro, lleno de pequeñas manchas blancas, sus ojos eran como dos perlas brillantes, pero no tenía boca. \
    Lo que hacía a Rotori realmente especial era su capacidad para controlar el clima, haciendo brillar el sol en los días nublados, haciendo que nevara en pleno verano o provocando una suave brisa cuando quisiera. Vive en el Bosque Mágico, donde todas las criaturas viven en armonía. Conocido por su buen corazón y su amistad con todos.\
     En Senáquia existían innumerables seres mágicos, cada uno con su propia personalidad y apariencia. Sin embargo, había una criatura malvada llamada Tagon, que siempre intentaba alterar el equilibrio del Bosque Mágico, Tagon estaba constantemente tramando planes para crear confusión y caos, afectando la paz del bosque.\
     Un día, cuando una terrible tormenta amenazaba con destruir todo el Reino de Senachia, las criaturas del bosque acudieron a Rotori en busca de ayuda. Con determinación enfrentó la tormenta y usó sus poderes para calmar los furiosos vientos y las aguas embravecidas. Impidió que Tagon causara más daños al equilibrio del reino.\
     Después de su victoria sobre Tagon, Rotori fue aclamado como el guardián del Bosque Cintila y continuó trayendo alegría y equilibrio al reino, asegurando que todas las estaciones fueran mágicas y felices.\
     La leyenda de Rotori inspiró a los niños a cuidar la naturaleza y creer en la magia que se puede encontrar en todas partes, si sabes dónde buscar.',
    naeBoLore:
      'En Senáquia hay varias criaturas mágicas de todas las formas, incluso parecidas a vegetales, como Na y su hermano Bo. Son una especie de nabo, pero nacen como gemelos siameses que viven unidos el uno al otro.\
 Vivían en un acogedor terreno en Vale das Rízes, donde las casas estaban bajo tierra y los residentes eran hortalizas y legumbres. Siempre que alguien necesitaba unas buenas risas o un amigo con quien jugar, ahí estaban Na y Bo con sus juegos y su habilidad de esconderse entre la vegetación para animarles.',
    coepiLore:
      'Una criatura muy especial y traviesa llamada Coepi, con apariencia de búho, pero con peludas orejas de conejo que asomaban entre sus suaves plumas blancas. Fue lo que lo hizo único entre otros seres animales.\
 Le encantaba gastar bromas y hacer travesuras en Vale das Raízes. Era conocido por su risa contagiosa y su espíritu juguetón. Coepi vivía en el Bosque Cintila, un lugar lleno de árboles altos, arroyos burbujeantes y flores coloridas.\
 Los vecinos de Senáquia estaban preocupados por cuál sería la próxima trampa de Coepi. Porque con su habilidad de caminar silenciosamente como un conejo, siempre pasaba desapercibido para el guardián del bosque y sorprendía a todos con sus trucos.',
    ibaLore:
      'Donde todas las criaturas se inspiraron en la naturaleza, había una muy especial llamada Ibá. Este ser mágico se parecía al tronco de un árbol, pero tenía una expresión amistosa en su cara de madera. Era conocido por su sabiduría y su capacidad para conectarse con la naturaleza de una manera única.\
 Ibá vivía en el corazón del Gran Bosque, un lugar donde los árboles eran altos y majestuosos, y los arroyos cantaban entre las piedras. Era hijo del bosque y era amado por todas las criaturas que allí vivían. Ibá solía sentarse al pie del viejo árbol, donde escuchaba muchas historias sabias que ella le contaba.\
 Sus poderes eran esenciales para el crecimiento de flores y árboles, mantuvo todo funcionando a su ritmo y trajo armonía al Bosque Resplandeciente.',
    tagonLore:
      'Mientras que todas las criaturas eran conocidas por su felicidad y bondad, había una criatura que destacaba por su actitud gruñona. Su nombre era Tagon y parecía una maraña de pelaje oscuro, tenía el ceño fruncido y su voz era murmurada.\
 Tagon vivía en las profundidades del Pantano Grumblor, un lugar donde los árboles estaban sin hojas y reinaba el silencio. Prefería la soledad y rara vez interactuaba con otras criaturas del bosque. Su pequeña casa estaba escondida entre las sombras de los árboles, y Tagon pasaba sus días dormitando y murmurando sobre el ruido constante del mundo exterior.\
 Sin embargo, sentía mucha envidia de la armonía y felicidad del Bosque Espumoso donde vivían la mayoría de las criaturas, un lugar que siempre estaba feliz y lleno de vida. A menudo se le veía pisoteando flores y destruyendo el bosque.',
    terryLore:
      'Tery, nacido en el Valle de las Raíces, tenía forma de berenjena, pero tenía piernas y una hoja en la parte superior de la cabeza, además de ojos pequeños y brillantes. Su personalidad se parecía a la de un cachorro.\
 Era conocido por correr siempre en el Sunny Field. Era un compañero leal y siempre estaba dispuesto a jugar y explorar los vastos paisajes del reino. Sin embargo, había un lugar al que nunca se arriesgó a ir, el Pantano Grumblor, ese lugar le daba escalofríos y además, allí vivía el problemático Tagon, que odiaba que lo molestaran.',
    previaPontosAtenção: 'Vista previa de puntos de atención',
    areasMelhoria: 'Áreas de Mejora',
    pontosFortes: 'Puntos fuertes',
    maisDetalhes: 'Más detalles',
    emocionometroNaoPreenchido: 'El emoticómetro no se ha llenado ni una vez, ¡no hay información para mostrar!',
    pontosAtenção: 'Puntos de Atención',
    geral: 'General',
    explicacaoGeral:'En el análisis general se presentarán dos tarjetas enfatizando áreas que resaltarán los puntos positivos y los logros notables.',
    naoHaInformacoesSuficientes:'¡No hay información suficiente para ser exhibida! recomendamos que se dedique al desarrollo de habilidades con sus estudiantes para visualizar la información.',
    explicacaoTurma:'El propósito de esta presentación es analizar las habilidades en las clases, enfocándose en aquella que requiere atención y en la que se destaca positivamente.',
    comparativo:'Comparativo',
    explicacaoComparativo:'En este análisis, identificamos al estudiante con la habilidad menos desarrollada y la comparamos con el promedio de la clase en la misma habilidad.',
    atuar:'Actuar',
    explorar:'Explorar',
    conhecimento:'Conhecimiento',
    comportamento:'Comportamiento',
    percepcao:'Percepción',
    conhecimientoDescricao:'Resultados obtenidos a partir de los análisis que investigan el nivel de comprensión de los conceptos relacionados con las habilidades socioemocionales.',
    comportamientoDescricao:'Resultados obtenidos a partir de los análisis que investigan la aplicación práctica de las habilidades socioemocionales.',
    percepcaoDescricao:'Resultados obtenidos a partir de los análisis que investigan la capacidad de reconocer las habilidades socioemocionales exteriorizadas por otras personas.',
    semDados:'Sin Datos',
    totalDeRespostas:'Respuestas totales',
    gerenciarEstudantes:'Administrar Estudiantes',
    gerenciarTurmas:'Administrar Clases',
    gerenciarColaboradores:'Administrar Colaboradores',
    gerenciarPerfis:'Administrar Perfiles',
    dadosMinhaOrganizacao:'Detalles de mi organización',
    dadosMinhaEscola:'Detalles de mi escuela',
    minhaEscola:'Mi escuela',
    instituição:'Institución',
    criarTurma:'Crear clase',
    turmaSérie:'Clase/Serie',
    nomeSérie:'Nombre/Serie',
    chave:'Llave',
    quantidadeDeEstudantes:'Numero de estudiantes',
    qtdEstudantes:'Numero de Estudiantes',
    anoLetivo:'Año Académico',
    painelDaTurma:'Panel de la Clase',
    acoes:'Acciones',
    excluirTurmaConfirmacao:'¿Está seguro de que desea excluir el registro de la clase?',
    turmaExcluidaComSucesso:'¡La clase fue excluida con éxito!',
    erroAoExcluirTurma:'Hubo un problema al excluir la clase. Verifique su conexión e inténtelo de nuevo.',
    excluirTurmasConfirmacao:'¿Está seguro de que desea excluir el registro de las clases?',
    turmasExcluidasComSucesso:'¡Las clases fueron excluidas con éxito!',
    erroAoExcluirTurmas:'Hubo un problema al excluir las clases. Verifique su conexión e inténtelo de nuevo.',
    deletar:'Borrar',
    rematricula:'Rematriculación',
    visualizar:'Vista',
    verPainel:'Ver panel',
    turno:'Cambio',
    Estudantes:'Estudiantes',
    professores:'Profesores',
    habilidades:'Habilidades',
    desenvolvimentoDeCompetencias:'Desarrollo de Competencias y Habilidades',
    analiseDoClimaEscolar:'Análisis del clima escolar',
    tipoDeComparação:'Tipo de comparación',
    modoComparativo:'Modo Comparativo',
    selecioneATurma:'Seleccionar clase',
    selecioneOEstudante:'Seleccionar estudiante',
    areaDeFoco:'Área de enfoque',
    selecionarTodos:'Seleccionar todos',
    recomendacoesPedagogicas:'Recomendaciones pedagógicas',
    feliz:'Feliz',
    triste:'Triste',
    medo:'Miedo',
    nojo:'Asco',
    raiva:'Ira',
    totalDeAtividades:'Total de Actividades',
    concluidas:'Concluidas',
    emProgresso:'En Progreso',
    naoIniciadas:'No Iniciadas',
    rotaDeAprendizado:'Ruta de Aprendizaje',
    limparFiltro:'Limpiar filtro',
    trilha:'Sendero',
    avaliacaoInicial:'Evaluación Inicial',
    inicio:'Inicio',
    termino:'Término',
    jogoEscolhido:'Juego Escogido',
    progresso:'Progreso',
    concluidoEm:'Concluido en',
    registrosEmMeuCantinho:'Registros en “Mi Cantinho”',
    meuCantinho:'Mi Cantinho',
    mostrarMais:'Mostrar Más',
    totalDeRegistros:'Total de Registros',
    naoClassificado:'No clasificado',
    coracaoQuentinho:'Corazón cálido',
    bomFicarDeOlho:'Es bueno estar atento',
    redobreAtencao:'Redobla la atención',
    problemaSerio:'Problema serio',
    finalizar:'Finalizar',
    dadosGerais:'Datos Generales',
    equipe:'Equipo',
    pedagogica:'Pedagógica',
    equipePedagogica:'Equipo Pedagógico',
    editada:'editada',
    criada:'creada',
    comSucesso:'con éxito!',
    houveUmProblemaAo:'Hubo un problema al',
    aTurma:'la turma',
    verifiqueSuaConexaoETenteNovamente:'Verifique su conexión e inténtelo de nuevo.',
    modoDemonstrativo:'Modo Demostrativo',
    mensagemDeErroAnoLetivo:'Por favor, informe el año académico!',
    mensagemDeErroSerie:'Por favor, informe la serie!',
    mensagemDeErroTurno:'Por favor, informe el turno!',
    mensagemDeErroNomeDaTurma:'Por favor, escriba el nombre de la turma!',
    mensagemDeErroDataDeExpiracao:'Por favor, informe la fecha de expiración!',
    dataDeExpiracao:'Fecha de expiración',
    desejaCriarUmaAvaliacaoInicial:'¿Desea crear una evaluación inicial?',
    mensagemDeErroAvaliacaoInicial:'Por favor, elija si desea crear una evaluación inicial o no!',
    integral:'Integral',
    sim:'Sí',
    nao:'No',
    aviso30DiasTurma : 'Atención: Después de 30 días de la creación de la turma, no será posible editar los campos "Año académico", "Serie" y "Turno".',
    baixarQrCode:'Descargar Código QR',
    instrucoesQrCode:'Es hora de probar nuestra plataforma en la práctica en el modo educador. Siga estos pasos:',
    instrucao1:'Escanea el código QR con la cámara de tu celular.',
    instrucao2:'Regístrate insertando tu nombre o apodo y haz clic en "ENTRAR" para acceder a la página principal.',
    instrucao3:'Ahora es solo disfrutar y explorar nuestra plataforma.',
    visualizarQrCode:'Visualizar Código QR',
    nomePerfil:'Nombre/Perfil',
    situacao:'Situación',
    fechar:'Cerrar',
    perfilDe:'Perfil de',
    educador:'Educador',
    todosOsColaboradores:'Todos los Colaboradores',
    selecionados:'Seleccionados',
    ateAgoraNenhumColaboradorEscolhido:'Hasta ahora, ningún colaborador ha sido seleccionado. Por favor, seleccione los colaboradores para visualizarlos aquí.',
    naoExistemMaisColaboradoresParaExibicao:'No existen más colaboradores para exibir. Todos los colaboradores ya han sido seleccionados.',
    estudantesDaTurma:'Estudiantes de la Turma',
    nomeChave:'Nombre/Clave',
    emAndamento:'En Progreso',
    naoIniciada:'No Iniciada',
    concluido:'Concluido',
    expirada:'Expirada',
    exEstudante:'Ex-Estudiante',
    todosOsEstudantes:'Todos los Estudiantes',
    naoExistemMaisEstudantesParaExibicao:'No existen más estudiantes para exibir. Todos los estudiantes ya han sido seleccionados.',
    ateAgoraNenhumEstudanteEscolhido:'Hasta ahora, ningún estudiante ha sido seleccionado. Por favor, seleccione los estudiantes para visualizarlos aquí.',
    aAvaliacaoInicialÉCompostaPor:'La evaluación inicial está compuesta por',
    sendoElas:'siendo ellas',
    daCompetencia:'de la competencia',
    rematriculaEfetuadaComSucesso:'Rematrícula efectuada con éxito!',
    mensagemDeErroRematricula:'Hubo un problema al efectuar la rematrícula. Verifique su conexión e inténtelo de nuevo.',
    listaDeChavesDeAcessosDosEstudantes:'Lista de Claves de Accesos de los Estudiantes',
    versaoColorida:'Versión Colorida',
    versaoEscalasDeCinza:'Versión escalas de gris',
    modoBilhete:'Modo Billete',
    baixarTabela:'Descargar tabla',
    baixarChaveDosEstudantes:'Descargar clave de los estudiantes',
    tecnologiaSocioemocional:'¡U4Hero! Tecnología Socioemocional',
    nomeDaEscola:'Nombre de la Escuela',
    mensagemBoasVindasAluno:'Hola, bienvenido(a) a U4Hero! Estás a solo 2 pasos de iniciar tu jornada de diversión y aprendizaje:',
    passo1Bilhete : 'Descarga nuestra app en las tiendas (Play Store, Windows Store o App Store) o accede directamente por el navegador',
    passo2Bilhete : 'Ingresa tu clave de acceso y haz clic en REGISTRAR.',
    prepareSeParaUmaExperienciaIncrível:'Prepárate para una experiencia increíble de desarrollo y crecimiento con la',
    qualquerDuvidaMandeUmEmailPara:'Cualquier duda envía un correo electrónico a',
    chavesDeAcesso:'Claves de Acceso',
    nomeDoEstudante:'Nombre del Estudiante',
    serieTurma:'Serie/Turma',
    sérieTurma: 'Serie/Turma',
    ultimoAcesso:'Último acceso',
    ultimaResposta:'Última respuesta',
    painelDoEstudante:'Panel del estudiante',
    temCertezaQueDeseja:'¿Tienes la certeza de que deseas',
    restaurar:'Restaurar',
    oCadastroDoEstudante:'el registro del estudiante ?',
    cadastro:'Registro',
    excluido:'excluido',
    restaurado:'restaurado',
    opsAconteceuAlgumProblemaAoTentar:'Ups, hubo algún problema al intentar',
    oCadastro:'el registro',
    tenteNovamente:'intenta de nuevo!',
    gerarNovaChave:'Generar Nueva Clave',
    ultimoAcessoApartirDe:'Último acceso a partir de',
    estudantesDesativados:'Estudiantes desactivados',
    estudantesComChaveAtiva:'Estudiantes con clave activa',
    estudantesSemTurma:'Estudiantes sin turma',
    naoIniciado0:'No Iniciado (0%)',
    emAndamento179:'En Progreso (1% - 79%)',
    concluido100:'Concluido (100%)',
    cadastroAtualizado:'Registro actualizado',
    estudanteCadastrado:'Estudiante registrado',
    mensagemErroCadastro:'Ups, hubo algún problema al intentar efectuar el registro, inténtelo de nuevo!',
    mensagemSairSemSalvar:'No ha salvado la información que insertó, ¿desea salir de todas maneras?',
    informacoesNaoSalvas:'¡Información no salvada!',
    desejaRealmenteRealizarEstaOperacao:'¿Desea realmente realizar esta operación?',
    temCerteza:'¿Tiene la certeza?',
    operacaoRealizadaComSucesso:'¡Operación realizada con éxito!',
    problemaAoRealizarOperacao:'¡Hubo un problema al realizar esta operación!',
    informacoesDoEstudante:'Información del estudiante',
    matricula:'Matrícula',
    mensagemErroMatricula:'¡Por favor, informe el número de la matrícula!',
    nomeCompleto:'Nombre Completo',
    mensagemErroNomeCompleto:'¡Por favor, informe el nombre completo del estudiante!',
    dataDeNascimento:'Fecha de Nacimiento',
    mensagemErroDataDeNascimento:'¡Por favor, informe la fecha de nacimiento!',
    mensagemErroSexo:'¡Por favor, informe el sexo del estudiante!',
    informacoesDoResponsavel:'Información del Responsable',
    mensagemErroTelefone:'El teléfono debe tener al menos 10 números!',
    nomeDoResponsavel2:'Nombre del Responsable (2)',
    nomeDoResponsavel1:'Nombre del Responsable (1)',
    informacoesAcademicas:'Información Académica',
    mensagemErroImportacao:'¡Ups, hubo algún problema al intentar realizar la importación, inténtalo de nuevo!',
    importacaoRealizadaComSucesso:'¡Importación realizada con éxito!',
    mensagemVerificarImportacao:'No importaste el archivo que insertaste. ¿Deseas salir de todas maneras?',
    mensagemImportacao:'Si deseas registrar varios estudiantes a la vez, ofrecemos la opción de importar datos a través de una tabla. Puedes consultar el modelo a continuación, los campos de nombre, fecha de nacimiento, documento y sexo son obligatorios.',
    baixarModelo:'Descargar modelo',
    cliqueAquiOuArrasteAplanilha:'Haz clic aquí o arrastra la hoja',
    bomComportamento: 'Buen Comportamiento',
    mauComportamento: 'Mal Comportamiento',
    todos: 'Todos',
    observacao: 'Observación',
    selecioneOsComportamentos: 'Seleccione los comportamientos',
    tipoDeObservacao: 'Tipo de Observación',
    observacoesAdicionaisOpcional: 'Observaciones adicionales (opcional)',
    observacaoAdicional: 'Observación adicional',
    historicoDeObservacoes: 'Historial de Observaciones',
    comportamentosSelecionados: 'Comportamientos seleccionados',
    mostrarMenos: 'Mostrar menos',
    lerMais: 'Leer más',
    bomComportamento1: 'Ayudó a organizar la sala de clases.',
    bomComportamento2: 'Presentó actitudes de liderazgo.',
    bomComportamento3: 'Presentó actitudes de responsabilidad ambiental.',
    bomComportamento4: 'Colaboró bien en actividades de grupo.',
    bomComportamento5: 'Colaboró en el mantenimiento del orden en la sala.',
    bomComportamento6: 'Completó las tareas con calidad.',
    bomComportamento7: 'Completó las tareas con rapidez.',
    bomComportamento8: 'Contribuyó a la resolución de conflictos.',
    bomComportamento9: 'Contribuyó a un ambiente positivo.',
    bomComportamento10: 'Demostró empatía por los compañeros.',
    bomComportamento11: 'Expresó entusiasmo por el aprendizaje.',
    bomComportamento12: 'Fue proactivo en buscar ayuda.',
    bomComportamento13: 'Fue proactivo en la resolución de problemas.',
    bomComportamento14: 'Manifestó interés en la disciplina.',
    bomComportamento15: 'Mostró respeto por los compañeros.',
    bomComportamento16: 'Participó activamente en las discusiones en clase.',
    bomComportamento17: 'Participó en actividades extracurriculares.',
    bomComportamento18: 'Participó en eventos escolares.',
    bomComportamento19: 'Tuvo buena relación con los compañeros.',
    bomComportamento20: 'Tuvo interés en aprender otros contenidos.',
    mauComportamento1: 'Agredió a un compañero.',
    mauComportamento2: 'Presentó comportamiento desrespetuoso.',
    mauComportamento3: 'Demostró falta de interés en la materia.',
    mauComportamento4: 'Olvidó los materiales.',
    mauComportamento5: 'Se distrajo durante la clase.',
    mauComportamento6: 'Permaneció de pie toda la clase.',
    mauComportamento7: 'Manifestó falta de empatía por los compañeros.',
    mauComportamento8: 'Mostró falta de organización.',
    mauComportamento9: 'Mostró ansiedad durante pruebas o evaluaciones.',
    mauComportamento10: 'No aceptó las críticas.',
    mauComportamento11: 'No completó las tareas de casa.',
    mauComportamento12: 'No dejó de hablar toda la clase.',
    mauComportamento13: 'No quiso trabajar en grupo.',
    mauComportamento14: 'No respetó las reglas de la clase.',
    mauComportamento15: 'Hizo/Recibió trampa durante la evaluación.',
    mauComportamento16: 'Tuvo dificultad en aceptar la autoridad de los compañeros.',
    mauComportamento17: 'Tuvo dificultad en aceptar opiniones divergentes.',
    mauComportamento18: 'Usó lenguaje inapropiado.',
    mauComportamento19: 'Usó el celular durante la clase.',
    mauComportamento20: 'Usó ropa inapropiada para la escuela.',
    outroEspecifique: 'Otro (Especifique abajo).',
    bomFicarDeOlho1: 'Demostró falta de autoconfianza.',
    bomFicarDeOlho2: 'Durmió durante la clase.',
    bomFicarDeOlho3: 'Permaneció aislado de los compañeros.',
    bomFicarDeOlho4: 'Fue blanco de acoso escolar.',
    bomFicarDeOlho5: 'Fue víctima de discriminación.',
    bomFicarDeOlho6: 'Tuvo dificultad en controlar impulsos.',
    bomFicarDeOlho7: 'Tuvo dificultad en expresar emociones.',
    bomFicarDeOlho8: 'Tuvo dificultad en seguir instrucciones.',
    historicoDeAcessos: 'Historial de Accesos',
    matriculado: 'Matriculado',
    eMailDeAcessoEnviadoComSucesso: 'Correo electrónico de acceso enviado con éxito!',
    erroAoEnviarEmail: 'Hubo un problema al enviar el correo electrónico. Verifique su conexión e inténtelo de nuevo.',
    colobadorJaExiste:'Colaborador no registrado! Ya existe un registro con el mismo correo electrónico.',
    erroAoSalvarAlteracoes: 'No fue posible guardar los cambios. Verifique su conexión e inténtelo de nuevo.',
    erroAoCadastrarColaborador: 'Hubo un problema al registrar el colaborador. Verifique su conexión e inténtelo de nuevo.',
    alteracaoConcluidaComSucesso: 'El cambio fue concluido con éxito!',
    colaboradorCriadoComSucesso: 'Colaborador creado con éxito!',
    mensagemErroNomeColaborador: 'Por favor, informe el nombre del colaborador!',
    insiraONomeCompleto: 'Ingrese el nombre completo',
    insiraONomeDoColaborador: 'Ingrese el nombre del colaborador',
    email: 'Correo electrónico',
    mensagemAlteracaoEmail: 'Si necesita cambiar el correo electrónico, por favor, póngase en contacto con nuestro soporte para continuar el proceso de cambio. Estamos aquí para ayudar!',
    mensagemErroEmail: 'Por favor, informe el correo electrónico!',
    exemploEmail: 'ejemplo@ejemplo.com',
    mensagemErroCpf: 'Por favor, informe el número del cpf!',
    cpf: 'CPF',
    mensagemErroPerfil: 'Por favor, seleccione el perfil!',
    selecioneOPerfilCorrespondente: 'Seleccione el perfil correspondiente',
    alteracaoDeSenha: 'Cambio de Contraseña',
    novaSenha: 'Nueva Contraseña',
    insiraANovaSenha: 'Ingrese la nueva contraseña',
    reenviarEmailDeAcesso: 'Reenviar correo electrónico de acceso',
    importacaoDeColaboradoresConcluidaComSucesso: 'Importación de colaboradores concluida con éxito!',
    erroAoImportarColaboradores: 'Hubo un problema al importar los colaboradores. Verifique su conexión e inténtelo de nuevo.',
    mensagemImportacaoColaboradores: 'Si desea registrar varios colaboradores al mismo tiempo, ofrecemos la opción de importar datos a través de una tabla. Para esto, la hoja de cálculo deberá seguir el ejemplo del modelo proporcionado. En caso de que algún colaborador no tenga el campo "perfil" lleno, será automáticamente considerado como "educador".',
    status: 'Estado',
    oCadastroDoColaborador: 'el registro del colaborador?',
    oCadastroDoColaboradorFoi: 'El registro del colaborador fue',
    desativado: 'desactivado',
    ativado: 'activado',
    voceNaoTemPermissaoPara: 'Usted no tiene permiso para',
    doColaborador: 'del colaborador',
    formatoDeImagemInvalido: 'Formato de imagen inválido',
    tamanhoDaImagemInvalido: 'Tamaño de la imagen inválido',
    erroAoFazerUpload: 'Error al hacer upload!',
    formatosAceitos: 'Formatos aceptados',
    tamanhoMaximoDoArquivo: 'Tamaño máximo del archivo',
    carregarFoto: 'CARGAR FOTO',
    editadoComSucesso: 'editado con éxito.',
    cadastradoComSucesso: 'registrado con éxito.',
    mensagemErroNomePerfil: 'Por favor, ingrese el nombre del perfil!',
    atribuaUmNomeParaEssePerfil: 'Asigne un nombre a este perfil',
    gerenciamentoDePermissoes: 'Gestión de Permisos',
    nomeDoPerfil: 'Nombre del Perfil',
    acessoCompleto: 'Acceso Completo',
    dataDeCriacao: 'Fecha de creación',
    temCertezaQueDesejaExcluirOPerfilDe: '¿Está seguro de que desea excluir el perfil de',
    perfilExcluidoComSucesso: 'Perfil excluido con éxito!',
    erroAoExcluirPerfil: 'Hubo un problema al excluir el perfil. Verifique su conexión e inténtelo de nuevo.',
    perfilAtualizadoComSucesso: 'Perfil actualizado con éxito!',
    perfilDaInstituicao: 'Perfil de la Institución',
    salvarAlteracoes: 'Guardar Cambios',
    informacoesDaInstituicao: 'Informaciones de la Institución',
    razaoSocial: 'Razón Social',
    porFavorInformeSeuNomeCompleto: 'Por favor, informe su nombre completo!',
    porFavorInformeONumeroDoCnpj: 'Por favor, informe el número del cnpj!',
    mensagemErroTelefoneInstituicao: 'Por favor, informe el teléfono de la institución!',
    nomeFantasia: 'Nombre Fantasía',
    trocarDirecao: 'Cambiar Dirección',
    direcaoEContato: 'Dirección y Contacto',
    nomeDoDiretor: 'Nombre del Director',
    mensagemErroCep: 'El CEP debe tener 8 dígitos',
    campoObrigatorio: 'Campo obligatorio!',
    logradouro: 'Calle',
    mensagemErroLogradouro: 'Por favor, informe la calle de la escuela!',
    ruaAvenidaTravessa: 'Calle, Avenida, Travessa...',
    mensagemErroBairro: 'Por favor, informe el barrio!',
    nomeDoBairro: 'Nombre del barrio',
    aptoCasa: 'Apto, casa...',
    mensagemErroEstado: 'Por favor, informe el estado!',
    mensagemErroCidade: 'Por favor, informe la ciudad!',
    progressoDoPerfil: 'Progreso del Perfil',
    menosDetalhes: 'Menos Detalles',
    pesquisar: 'Buscar',
    filtradoPor: 'Filtrado por',
    consulteAListaDeColaboradoresCadastrados: 'Consulte la lista de colaboradores registrados y seleccione el director de la escuela',
    visaoGeral: 'Visión General',
    catalogo: 'Catálogo',
    informacoesDoJogo: 'Informaciones del Juego',
    atividades: 'Actividades',
    geografia: 'Geografía',
    historia: 'Historia',
    matematica: 'Matemáticas',
    duracao: 'Duración',
    temaExtraAbordado: 'Tema Extra Abordado',
    faixaEtaria: 'Rango de Edad',
    anosMais: 'años +',
    desfavoritar: 'Desfavoritar',
    favoritar: 'Favoritar',
    testarJogo: 'Probar Juego',
    objetivo: 'Objetivo',
    autor: 'Autor',
    esteJogoFoiDesenvolvidoPor: 'Este juego fue desarrollado por',
    agradecemosAoAutorPorSeuTrabalho: 'Agradecemos al autor por su trabajo!',
    desenvolvidoPelaEquipe: 'Desarrollado por el equipo U4Hero, este juego es una creación exclusiva.',
    carregandoJogo: 'Cargando juego...',
    mensagemPreviaConcluida:' Esperamos que hayas disfrutado de esta muestra! Cuando estés listo, haz clic en "Concluir" para finalizar la vista previa.',
    concluir: 'Concluir',
    previaDoJogo: 'Vista Previa del Juego',
    estratégia: 'Estrategia',
    puzzle: 'Rompecabezas',
    simulação: 'Simulación',
    classificacao: 'Clasificación',
    jogosJaUtilizados: 'Juegos ya utilizados',
    jogosNaoUtilizados: 'Juegos no utilizados',
    duracaoDoJogo: 'Duración del Juego',
    ate: 'Hasta',
    temasExtras: 'Temas Extras',
    limiteDePerguntas: 'Límite de Preguntas',
    favoritos: 'Favoritos',
    acompanheOsIndicadores: 'Sigue los Indicadores y las Novedades de los Juegos en Tiempo Real',
    jogosSendoUtilizados: 'Juegos siendo utilizados',
    taxaDeParticipacao: 'Tasa de Participación',
    emBreve: 'En breve',
    ultimosAcessos: 'Últimos Accesos',
    novidades: 'Novedades',
    mensagemCarroselJogos:'Descubre un nuevo universo de aprendizaje a través de los juegos, donde tus estudiantes pueden desarrollar habilidades socioemocionales de manera envolvente y divertida.',
    feedbacks: 'Feedbacks',
    centralDePerguntas: 'Central de Preguntas',
    enunciado: 'Enunciado',
    habilidadesCamposDeConhecimento: 'Habilidades/ Campos de conocimiento',
    escopo: 'Alcance',
    tooltipPrivada: 'Las preguntas privadas solo pueden ser utilizadas por el autor de la pregunta y el administrador de la escuela.',
    tooltipPublica: 'Las preguntas públicas pueden ser utilizadas por todos los miembros de la escuela.',
    tooltipGlobal: 'Las preguntas globales pueden ser utilizadas por todas las escuelas de U4Hero.',
    aPergunta: 'la pregunta',
    aPerguntaFoi:'La pregunta fue',
    voceNaoTemPermissaoParaAtivarEstaPergunta: '¡No tienes permiso para cambiar el estado de esta pregunta!',
    feedback: 'Feedback',
    muitoRuim: 'Muy Mal',
    ruim: 'Mal',
    regular: 'Regular',
    bom: 'Bueno',
    muitoBom: 'Muy Bueno',
    boa: 'Buena',
    categoria: 'Categoría',
    data: 'Fecha',
    icone: 'Ícono',
    avaliacao: 'Evaluación',
    aPartirDe: 'A partir de',
    naoHa: 'No hay',
    privada: 'Privada',
    publica: 'Pública',
    global: 'Global',
    competenciaHabilidade: 'Competencia/Habilidad',
    ambienteAnalise: 'Ambiente de Análisis',
    tipoAnalise: 'Tipo de Análisis',
    tipoPergunta: 'Tipo de Pregunta',
    muitoBaixa: 'Muy Baja',
    baixa: 'Baja',
    moderado: 'Moderada',
    muitoBoa: 'Muy Buena',
    discordoCompletamente: 'Discrepo Completamente',
    discordo: 'Discrepo',
    neutro: 'Neutral',
    concordo: 'Concordo',
    concordoCompletamente: 'Concordo Completamente',
    detalhesDaPergunta: 'Detalles de la Pregunta',
    mensagemErroTipoAnalise: '¡Por favor, selecciona qué estará evaluando la pregunta!',
    mensagemErroAmbienteAnalise: '¡Por favor, selecciona el ambiente de análisis!',
    mensagemErroFaixaEtaria: '¡Por favor, determina el rango de edad, entre 5 y 18 años!',
    aplicavelEm: 'Aplicable en',
    saibaMaisSobreAsFaixasEtarias: 'Aprende más sobre los rangos de edad',
    mensagemErroEnunciado: '¡Por favor, crea un enunciado!',
    digiteAafirmaçãoDaEscalaLikertAqui: 'Escribe la afirmación de la escala Likert aquí',
    placeholderLikert: 'Inserta una afirmación para que los encuestados puedan indicar su grado de acuerdo o desacuerdo.',
    placeholderEnunciado: 'Crea el enunciado de la pregunta',
    mensajeErroTipoAfirmaçãoLikert: '¡Por favor, define el tipo de afirmación likert!',
    estaAfirmaçãoÉ: 'Esta afirmación es',
    positiva: 'Positiva',
    negativa: 'Negativa',
    imagemPNGouJPGate3Mb: 'Imagen PNG o JPG hasta 3Mb.',
    alternativa: 'Alternativa',
    excluirA: 'eliminar la',
    alternativaExcluidaComSucesso: '¡Alternativa eliminada con éxito!',
    mensajeErroAlternativa: '¡Por favor, crea una alternativa para la pregunta!',
    mensajeErroCompreensao: '¡Por favor, evalúa el grado de comprensión de la alternativa!',
    grauDeCompreensao: 'Grado de Comprensión',
    gerar:'Generar',
    sugestoes:'Sugerencias',
    sugestao:'Sugerencia',
    alternativas:'Alternativas',
    mensajeErroSalvarSemAlteracoes: '¡Haz algún cambio antes de guardar!',
    mensajeValidacaoAlternativas: 'Para garantizar la calidad de la pregunta, es necesario incluir al menos dos alternativas con diferentes grados de comprensión, como "muy baja" y "muy buena". Esto ayuda a evaluar de forma más precisa',
    erroAoCriarPergunta: 'Hubo un problema al crear la pregunta. Verifica tu conexión e inténtalo de nuevo.',
    perguntaCriadaComSucesso: '¡Pregunta creada con éxito!',
    formatoDaResposta: 'Formato de la Respuesta',
    mensajeErroFormatoDaResposta: '¡Por favor, selecciona el formato de la respuesta!',
    escalaLikert: 'Escala Likert',
    alternativasPersonalizadas: 'Alternativas Personalizadas',
    areaDeAnalise: 'Área de Análisis',
    definaAreaDeAnalise: '¡Define el área de análisis!',
    competencia: 'Competencia',
    focoDeAnaliseDoClimaEscolar: 'Foco de Análisis del Clima Escolar',
    escolhaDoTipoDeTreinamento: 'Elección del Tipo de Entrenamiento',
    mensajeErroCompetencia: '¡Por favor, selecciona una competencia!',
    mensajeErroHabilidade: '¡Por favor, selecciona una habilidad!',
    mensajeErroClimaEscolar:'¡Define cuál será el foco de análisis del clima escolar!',
    mensajeErroTreinamento: '¡Define el tipo de entrenamiento!',
    mensajeErroAreaDefoco:'¡Define el área de Foco',
    mensajeDuasCompetencias:'¡Ya has seleccionado dos competencias, no es necesario seleccionar una habilidad!',
    informacoesAdicionais: 'Informaciones Adicionales',
    mensajeErroTipoPergunta: '¡Por favor, selecciona cuál es el tipo de la pregunta!',
    mensajeErroEscopo: '¡Por favor, define el alcance de la pregunta',
    treinamento: 'Entrenamiento',
    climaEscolar: 'Clima Escolar',
    pesquisa: 'Investigación',
    mensajeErroAreaDeAnalise: '¡Por favor, define el área de análisis deseada',
    selecioneAreaDeAnaliseDesejada: 'Selecciona el área de análisis deseada:',
    atividade: 'Actividad',
    situacional: 'Situacional',
    moderada: 'Moderada',
    invalido: 'Inválido',
    modoVisualizacao: 'Modo Visualización',
    dublagem: 'Doblaje',
    desativou: 'Desactivó',
    ativou: 'Activó',
    movimentacao: 'Movimiento',
    explicacaoFaixaEtaria:'Al crear una pregunta, es crucial seleccionar el rango de edad adecuado para garantizar la adecuación del contenido a la edad y al desarrollo de los estudiantes.',
    explicacaoFaixaEtaria2:'Además, es importante observar las reglas específicas para dirigir la pregunta a la clase correcta.',
    à:'a',
    atividadeDaTurma:'Actividad del Grupo', 
    atividadeIndividual:'Actividad Individual',
    avaliacaoDaTurma:'Evaluación del Grupo',
    avaliacaoIndividual:'Evaluación Individual',
    responsavel:'Responsable',
    qtd:'Cantidad.',
    tempoRestante:'Tiempo Restante',
    voceNaoEoResponsavelPelaAtividade:'¡Tú no eres el responsable de la actividad!',
    voceNaoEoResponsavelPelaAutomacao:'¡Tú no eres el responsable de la automatización!',
    excluirAtividadeConfirmacao:'¿Estás seguro de que deseas excluir la actividad?',
    atividadeExcluidaComSucesso:'¡La actividad fue excluida con éxito!',
    erroAoExcluirAtividade:'Hubo un problema al excluir la actividad. Verifica tu conexión e inténtalo de nuevo.',
    dataDeInicio:'Fecha de Inicio',
    dataDeTermino:'Fecha de Término',
    pesquisarPeloResponsavel:'Buscar por el responsable',
    frequencia:'Frecuencia',
    excluirAutomacaoConfirmacao:'¿Estás seguro de que deseas excluir la automatización?',
    automacaoExcluidaComSucesso:'¡La automatización fue excluida con éxito!',
    erroAoExcluirAutomação:'Hubo un problema al excluir la automatización. Verifica tu conexión e inténtalo de nuevo.',
    automacao:'Automatización',
    prazo:'Plazo',
    definaOPrazodaAtividade:'Define el plazo de la actividad:',
    anterior:'Anterior',
    proximo:'Siguiente',
    registrosDoMeuCantinho:'Registros de mi rincón',
    naoHaNenhumRegistroNoMeuCantinho:'¡No hay ningún registro en mi rincón, no hay información para mostrar!',
    vejaVersaoCompleta:'Ver versión completa',
    selecioneAsTurmasASerAplicada:'Seleccione la(s) clase(s) en la que se aplicará la actividad',
    selecioneOsEstudantesQueATividadeSeraAplicada:'Seleccione el/los estudiante(s) en los que se aplicará la actividad',
    selecioneUmJogoParaATividade: 'Seleccione un juego para la actividad',
    minutos: 'minutos',
    utilizado: 'Utilizado',
    naoUtilizado: 'No utilizado',
    vez: 'vez',
    competenciasEHabilidades: 'Competencias y Habilidades',
    selecioneOQueSeraAbordado: 'Seleccione lo que se abordará',
    selecione:'Seleccione',
    as:'as',
    perguntasQueSeraoInseridasNaAtividade:'Preguntas que se insertarán en la actividad',
    selecioneOTipoDePerguntaDesejada:'Seleccione el tipo de pregunta deseada:',
    bancoDePerguntas:'Banco de Preguntas',
    bancoDePerguntasVazio:'No hay más preguntas disponibles para mostrar. ¡Por favor, cree más preguntas para ampliar las opciones en la base de datos!',
    selecioneOTipoDePerguntaParaVisualizarAsOpcoesDisponiveis:'¡Seleccione el tipo de pregunta para ver las opciones disponibles!',
    analise:'Análisis',
    ambiente:'Ambiente',
    ateAgoraNenhumaPerguntaFoiEscolhida:'Hasta ahora, no se ha elegido ninguna pregunta. ¡Por favor, seleccione las preguntas en la base de datos para verlas aquí!',
    visualizacaoDasAlternativas:'Visualización de las Alternativas',
    anonimo:'Anónimo',
    automatica:'Automática',
    registro:'Registro',
    lidas:'Leídas',
    naoLidas:'No leídas',
    naoIniciado: 'No iniciado',
    informacoesSalvasComSucesso: 'Informaciones salvadas con éxito!',
    acessoNegado: 'Acceso Negado!',
    mensagemAcessoNegadoMeuCantinho:'Recibiste un nuevo mensaje en el “Mi Rincón”. Sin embargo, ya no estás vinculado a esta clase y no puedes acceder al mensaje.',
    voltarParaPaginaInicial:'VOLVER A LA PÁGINA INICIAL',
    cantinhoDoEstudante:'Rincón del Estudiante',
    registroMeuCantinho: 'Registro Mi Rincón',
    statusDoAcolhimento: 'Estado del Acolhimiento',
    vocenãoTemPermissãoParaEditarEsteAcolhimento: '¡Tú no tienes permiso para editar este acolhimiento!',
    classificacaoDoEducador: 'Clasificación del Educador',
    classificacaoAutomatica: 'Clasificación Automática',
    classificacaoSocioemocional: 'Clasificación Socioemocional',
    classificacaoDoMeuCantinho: 'Clasificación del Mi Rincón',
    justificativaDaClassificacao: 'Justificación de la Clasificación',
    expressoesCoracaoQuentinho: 'Expresiones Corazón Quentinho',
    expressoesBomFicarDeOlho: 'Expresiones es bueno estar atento',
    expressoesRedobreAAtencao: 'Expresiones Redoble la atención',
    expressoesProblemaSerio: 'Expresiones Problema Serio',
    mensagemErroComentario: '¡Ups, hubo un problema al intentar publicar tu comentario. Verifica tu conexión e inténtalo de nuevo!',
    comentarioPostadoComSucesso: '¡Comentario publicado con éxito!',
    comentarioNaoPodeSerVazio: '¡El comentario no puede ser vacío!',
    nenhumComentarioAteAgora: '¡Ningún comentario hasta ahora, sé el primero en comentar!',
    insiraSeuComentario: '¡Inserta tu comentario!',
    preenchaAMensagemAntesDeEnviar: '¡Llena el mensaje antes de enviar!',
    mensagemErroEnviarMensagem: '¡Ups, hubo un problema al intentar enviar tu mensaje. Verifica tu conexión e inténtalo de nuevo!',
    mensagemEnviadaComSucesso: '¡Mensaje enviado con éxito!',
    chatAssincrono: 'Chat (Asíncrono)',
    enviarMensagemAcolhimento: '¡Envía un mensaje de acolhimiento para este estudiante!',
    insiraSuaMensagem: '¡Inserta tu mensaje!',
    meuPainel: 'Mi Panel',
    visaoEducador: 'Visión de Educador',
    visaoGestor: 'Visión de Gestor',
    visaoOrganizacao: 'Visión de Organización',
    painelDeIndicadores: 'Panel de Indicadores',
    temCertezaQueDesejaExcluirOIndicador: '¿Tiene la certeza de que desea excluir el indicador?',
    indicadorExcluidoComSucesso: 'El indicador fue excluido con éxito!',
    mensagemErroSalvarIndicador: 'Hubo un problema al salvar el indicador. Verifique su conexión e inténtelo de nuevo.',
    totalDeEstudantes: 'Total de estudiantes',
    totalDeTurmas: 'Total de turmas',
    totalDeProfessores: 'Total de profesores',
    totalDeColaboradores: 'Total de colaboradores',
    entendaSobreAsCompetenciasEHabilidades: 'Entienda sobre las Competencias y Habilidades',
    emocionometroDosUltimos7Dias: 'Emocionómetro de los últimos 7 días',
    graficoDuplicadoComSucesso: 'Gráfico duplicado con éxito! Ahora está editando una copia. Haga los cambios necesarios y guarde para crear un nuevo gráfico.',
    maisGraficos: '+ Gráficos',
    personalizeSeuPainelDeIndicadores: 'Personalice su Panel de Indicadores',
    exploreAPersonalizacaoDoSeuPainelDeIndicadores:'Explore la personalización de su panel de indicadores! Haga clic en el botón abajo para empezar a montar su panel y elija los indicadores que desea visualizar. Dê vida a sus datos y tenga insights relevantes sobre el desarrollo socioemocional de los estudiantes.',
    iniciarPersonalizacao: 'Iniciar Personalización',
    temporal: 'Temporal',
    ano: 'Año',
    copiar: 'Copiar',
    frequenciaDosEstudantes: 'Frecuencia de los Estudiantes',
    sugestoesParaVoce: 'Sugerencias para usted',
    visaoDaEscolaAtividades: 'Visión de la Escuela - Actividades',
    atividadesNaEscola: 'Actividades en la Escuela',
    indicadoresAtualizados: 'Indicadores actualizados',
    indicadoresIncluidos: 'Indicadores incluidos',
    incluir: 'incluir',
    osIndicadores: 'los indicadores',
    adicionarIndicadores: 'Agregar Indicadores',
    frequenciaDosEducadores: 'Frecuencia de los Educadores',
    opcoesPreCriadas: 'Opciones Pre-Creadas',
    personalizacaoDosGraficos: 'Personalización de los Gráficos',
    grafico: 'Gráfico',
    anoDeAnalise: 'Año de Análisis',
    mensagemErroAnoDeAnalise: '¡Por favor, defina el año a ser considerado!',
    definaOsIndicadores: '¡Por favor, defina los indicadores!',
    indicadores: 'Indicadores',
    escolhaOsIndicadores: 'Escoja los indicadores',
    estiloDoGrafico: 'Estilo del Gráfico',
    mensagemErroEstiloDoGrafico: '¡Por favor, escoja el tipo del gráfico!',
    nomeDoGrafico: 'Nombre del Gráfico',
    mensagemErroNomeDoGrafico: '¡Por favor, cree un nombre para este gráfico!',
    preenchaOsCamposParaContinuarCriandoOGráfico: 'Llene los campos para continuar creando el gráfico',
    crieUmNomeParaSeuGrafico: 'Cree un nombre para su gráfico',
    categoriaASerAAnalizada: 'Categoría a ser analizada',
    mensagemErroCategoriaASerAAnalizada: '¡Por favor, defina la categoría a ser analizada!',
    avaliacoesIniciais: 'Evaluaciones Iniciales',
    avaliacoes: 'Evaluaciones',
    definicaoDoPeriodoDeTempo: 'Definición del Período de Tiempo:',
    mensagemErroDefinicaoDoPeriodoDeTempo: '¡Por favor, defina el período de tiempo!',
    anual: 'Anual',
    bimestral: 'Bimestral',
    trimestral: 'Trimestral',
    quadrimestral: 'Cuatrimestral',
    semestral: 'Semestral',
    comparativo1: 'Comparativo 1',
    comparativo2: 'Comparativo 2',
    mensagemErroComparativo1: '¡Por favor, seleccione el comparativo 1!',
    mensagemErroComparativo2: '¡Por favor, seleccione el comparativo 2!',
    bimestre: 'Bimestre',
    trimestre: 'Trimestre',
    quadrimestre: 'Cuatrimestre',
    semestre: 'Semestre',
    modoDeComparacao: 'Modo de Comparación',
    mensagemErroModoDeComparacao: '¡Por favor, defina el modo de comparación!',
    periodoDeTempo: 'Período de Tiempo',
    estudanteVersusTurma: 'Estudiante versus Turma',
    modoDeVisualizacaoDoGrafico: 'Modo de Visualización del Gráfico:',
    mensagemErroModoDeVisualizacaoDoGrafico: '¡Por favor, defina el modo de visualización del gráfico!',
    selecioneASerie: 'Seleccione la serie',
    mensagemErroTurma: '¡Por favor, seleccione la turma!',
    mensagemErroSelecioneEstudante: '¡Por favor, seleccione el estudiante!',
    graficoDeLinha: 'Gráfico de línea',
    graficoDeArea: 'Gráfico de área',
    graficoDeBarra: 'Gráfico de barra',
    graficoDeRadar: 'Gráfico de radar',
    graficoDePizza: 'Gráfico de pizza',
    graficoDeDonut: 'Gráfico de donut',
    graficoDeColuna: 'Gráfico de coluna',
    graficoDeColunaEmpilhada: 'Gráfico de coluna empilhada',
    dadosASeremExibidosReferenteA: 'Datos a ser exibidos, referente a:',
    mensagemErroDadosASeremExibidos: '¡Por favor, defina los datos a ser exibidos!',
    mensagemErroPeriodo: '¡Por favor, defina el período!',
    mes: 'Mes',
    nEstudantes: 'Nº Estudiantes',
    selecioneOPeriodoRelatorio: 'Seleccione el período que desea generar el informe.',
    dataFinal: 'Fecha final',
    selecioneORelatorioQueDesejaGerar: 'Seleccione el informe que desea generar.',
    relatorioEmocaoGeradoComSucesso: '¡Informe de emoción generado con éxito!',
    emocao: 'Emoción',
    dataPublicacao: 'Fecha de publicación',
    estudanteS: 'estudiante(s)',
    turmaS: 'turma(s)',
    comentarioPublicado: '¡Comentario publicado!',
    oComentarioNaoPodeSerVazio: 'El comentario no puede estar vacío.',
    escrevaSeuComentario: 'Escriba su comentario.',
    naoGostei: 'No me gustó',
    gostei: 'Me gustó',
    respostaDoEstudante: 'Respuesta del estudiante',
    respostas: 'Respuestas',
    obrigadoPeloSeuFeedback: '¡Gracias por su retroalimentación!',
    opsHouveAlgumErroTenteNovamente: '¡Ups, hubo un error, inténtelo de nuevo!',
    inspiracoesPedagogicas: 'Inspiraciones Pedagógicas',
    gerenciarInspracoes: 'GERENCIAR INSPIRACIONES',
    inspiracoesNoTotal: 'Inspiraciones en Total',
    novasInspracoes: 'Nuevas Inspiraciones',
    verTudo: 'Ver todo',
    min: 'min',
    novo: 'Nuevo',
    dificuldade: 'Dificultad',
    sugestoesDeInspiracoesPedagogicas: 'Sugerencias de Inspiraciones Pedagógicas',
    pesquisarPorNomeDaInspiracao: 'Buscar por nombre de la inspiración',
    recursos: 'Recursos',
    disciplinaRelacionada: 'Disciplina Relacionada',
    arte: 'Arte',
    ciencias: 'Ciencias',
    educacaoFisica: 'Educación Física',
    linguaInglesa: 'Lengua Inglesa',
    linguaPortuguesa: 'Lengua Portuguesa',
    modalidade: 'Modalidad',
    publicado: 'Publicado',
    rascunho: 'Borrador',
    inspiracoes: 'Inspiraciones',
    inspiracao: 'Inspiración',
    titulo: 'Título',
    ultimaAtualizacao: 'Última actualización',
    temCertezaQueDesejaExcluirAInspiracaoPedagogica: '¿Está seguro de que desea excluir la Inspiración pedagógica?',
    inspiracaoPedagogicaExcluidaComSucesso: 'La Inspiración pedagógica fue excluida con éxito!',
    erroAoExcluirInspiracaoPedagogica: 'Hubo un problema al excluir la Inspiración pedagógica. Verifique su conexión e inténtelo de nuevo.',
    temCertezaQueDesejaExcluirAsInspiracoesPedagogicas: '¿Está seguro de que desea excluir las Inspiraciones pedagógicas?',
    inspiracoesPedagogicasExcluidasComSucesso: 'Las Inspiraciones pedagógicas fueron excluidas con éxito!',
    erroAoExcluirInspiracoesPedagogicas: 'Hubo un problema al excluir las Inspiraciones pedagógicas. Verifique su conexión e inténtelo de nuevo.',
    avaliacaoGeral: 'Evaluación General',
    temCertezaQueDesejaDesfazerAsAlteracoes: '¿Está seguro de que desea deshacer las alteraciones?',
    alteracoesDesfeitasComSucesso: 'Alteraciones deshechas con éxito!',
    erroAoDesfazerAlteracoes: 'No fue posible deshacer las alteraciones. Verifique su conexión e inténtelo de nuevo.',
    personalizeEssaPraticaDeAcordoComSeuContexto: 'Personalice esta práctica según su contexto!',
    adaptarInspiracao: 'Adaptar Inspiración',
    essaPraticaPedagogicaFoiAlterada: 'Esta práctica pedagógica fue alterada,',
    cliqueAqui: 'clique aquí',
    seDesejaDesfazerAsAlteracoesEDesfazerParaAPraticaOriginal: 'si desea deshacer las alteraciones y regresar a la práctica original!',
    erroAoSalvarFeedback: '¡Ups, hubo un error al salvar su retroalimentación, inténtelo de nuevo!',
    comoVoceAvaliaEssaPraticaPedagogica: '¿Cómo evalúa esta práctica pedagógica?',
    alteracaoRealizadaComSucesso: 'Alteración realizada con éxito!',
    erroRecursoInspiracao: 'Por favor, describa el objetivo de la Inspiración pedagógica!',
    placeholderRecursos:'Cite los materiales que serán necesarios para realizar la Inspiración pedagógica, caso no haya escriba “no hay”....',
    erroConteudoInspiracao: 'Por favor, escriba el paso a paso de la inspiración!',
    formatoArquivoInvalido: 'Formato de archivo inválido',
    tamanhoArquivoInvalido: 'Tamaño del archivo inválido',
    cliqueOuArrasteUmaImagem: 'Clique o arraste una imagen',
    paraAdicionarAoFinalDoDocumento: 'para adicionar al final del documento',
    ouArquivo: 'o archivo',
    notificacoes: 'Notificaciones',
    ajuda: 'Ayuda',
    metricas: 'Métricas',
    instituições: 'Instituciones',
    publicacoes: 'Publicaciones',
    loja: 'Tienda',
    sejaBemVindoÀU4Hero: 'Bienvenido a U4Hero!',
    introducaoU4hero: 'Somos la primera plataforma de juegos socioemocionales de Brasil, ayudando a educadores a entender cómo las emociones impactan el desempeño escolar y social de los estudiantes. Ofrecemos juegos digitales divertidos y trayectorias de aprendizaje personalizadas. ¡Ven a conocer nuestra plataforma!',
    vamosLa: '¡Vamos allá!',
    jaConheco: '¡Ya conozco!',
    descubraMaisTarde: '¡Descubre más tarde!',
    descubraMaisTexto:'Podrás explorar de nuevo, o en otro momento, los recursos de nuestra plataforma. Para acceder es simple: basta clicar en tu foto de perfil en el menú y seleccionar "Explorar Recursos", o a través de la',
    centralDeAjuda: 'Central de Ayuda',
    obrigadoPorFazerParteDaNossaComunidade: 'Gracias por ser parte de nuestra comunidad y esperamos que tu jornada con nosotros sea de éxito!',
    pular: 'Saltar',
    continuar: 'Continuar',
    terminar: 'Terminar',
    mensagemTourConcluido:'¡Exploraste todos los recursos de nuestra plataforma! Estamos felices de tenerte con nosotros. Ahora que conoces todas las funcionalidades, aprovecha al máximo tu jornada profesional',
    detalhamentoDeEstudantesPorEmoções: 'Detallamiento de Estudiantes por Emociones',
    autenticacaoDeDoisFatoresFoi: 'La autenticación de dos factores',
    ativada: 'activada',
    desativada: 'desactivada',
    codigoAutenticacaoInvalido: 'El código de autenticación está inválido o expirado!',
    a: 'a',
    daAutenticacaoDeDoisFatoresFalhouPorfavorInserirOCodigoEnviadoPor:'de la autenticación de dos factores falló. Por favor, inserta el código enviado por email para concluir el proceso de desactivación.',
    ativacao: 'activación',
    desativacao: 'desactivación',
    teEnviamosUmNovoCodigoChequeSeuEmail: 'Te enviamos un nuevo código, ¡chequea tu email!',
    erroAoEnviarCodigo: 'Ocurrió un problema al enviar el código. ¡Inténtalo de nuevo!',
    segurancaReforcada: '¡Seguridad Reforzada!',
    desativacaoDaAutenticacao: 'Desactivación de la Autenticación!',
    mensagem2FaAtivado: 'Activaste la autenticación de dos factores. Un email con el código de seguridad fue enviado para ti',
    mensagem2FaDesativado: 'Desactivaste la autenticación de dos factores. Verifica tu email para insertar el código de seguridad y concluir el proceso.',
    digiteAquiOCodigoQueTeEnviamosPeloEmail: 'Digite aquí el código que te enviamos por email.',
    verificar: 'Verificar',
    recebimentoDoSeuCodigoPodeLevarAteUmMinuto: 'El recebimiento de tu código puede llevar hasta un minuto. Si no lo recibiste,',
    cliqueAquiParaSolicitarUmNovoCodigo: '¡clique aquí para solicitar un nuevo código!',
    acompanhamentoDaAtividade: 'Acompañamiento de la Actividad',
    totalDePerguntas: 'Total de Preguntas',
    dias: 'días',
    perguntasEducador: 'Preguntas Educador',
    perguntasPlataforma: 'Preguntas Plataforma',
    progressoDaTurma: 'Progreso de la Turma',
    media: 'Media',
    naoHaNotificacoes: '¡No hay notificaciones!',
    marcarTodasComoLidas: 'Marcar todas como leídas',
    maisRecentes: '¡Más recientes!',
    mostrarApenasNaoLidas: 'Mostrar solo no leídas',
    guiaRapido: 'Guía Rápida',
    irParaCentralDeAjuda: 'Ir a la Central de Ayuda',
    inicieOTourExplicativoDessaTela: 'Inicia el tour explicativo de esta pantalla',
    desejaIniciarOTour: '¿Desea iniciar el tour?',
    meuPerfil: 'Mi Perfil',
    mensagemErroSalvarAlteracoes:'Hubo un problema al guardar los cambios. Por favor, verifique su conexión e intente nuevamente.',
    informacoesPessoais: 'Informaciones Personales',
    contato: 'Contacto',
    seguranca: 'Seguridad',
    autenticacaoDeDoisFatores: 'Autenticación de dos factores',
    alterarSenha: 'Cambiar Contraseña',
    mensagemAlteracaoSenha: 'Tu contraseña ha sido cambiada con éxito. Por favor, inicia sesión nuevamente para seguir accediendo a tu cuenta con la nueva contraseña.',
    mensagemErroSenhaActual: 'La contraseña actual ingresada no corresponde a la contraseña correcta.',
    mensajeErroAlteracaoSenha :'Hubo un problema al cambiar la contraseña. Por favor, verifique su conexión e intente nuevamente.',
    senhaActual: 'Contraseña actual',
    mensajeErroSenhaActualObrigatorio: '¡Por favor, ingrese su contraseña actual!',
    mensajeErroNovaSenhaObrigatorio: '¡Por favor, ingrese su nueva contraseña!',
    mensajeErroSenhaMinimoCaracteres: 'La contraseña debe tener al menos 12 caracteres',
    mensajeErroSenhaCaracteresEspeciales: 'La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número y 1 carácter especial',
    confirmarSenha: 'Confirmar contraseña',
    mensajeErroSenhaConfirmacao: '¡Por favor, confirme su contraseña!',
    mensajeErroSenhasDiferentes: '¡Ups, las contraseñas no son iguales!',
    confirmarAlteracao: 'Confirmar Cambio',
    blogDeNovidades: 'Blog de Novedades',
    criarEstudante: 'Crear estudiante',
    criarAtividade: 'Crear actividad',
    camposDeConhecimentosEHabilidades: 'Campos de Conocimientos y Habilidades',
    oQueAchouDasNovidades: '¿Qué opinas de las novedades?',
    paraNosAjudarAMelhorarPorFavorExpliqueOMotivoDaSuaAvaliacao: 'Para ayudarnos a mejorar, por favor, explique el motivo de su evaluación:',
    expliqueOMotivoDaSuaAvaliacaoComOMaximoDeDetalhesPossivel: 'Explique el motivo de su evaluación con el máximo de detalles posible',
    activity: 'Actividad',
    leiaMais: 'Leer más',
    'In person': 'En persona',
    Remote: 'Remoto',
    Hybrid: 'Híbrido',
    Multiple: 'Múltiples Contextos',
    Familiar: 'Familiar',
    School: 'Escolar',
    Digital: 'Digital',
    Social: 'Social',
    sejaOProtagonistaDaSuaHistoriaConecteSeComU4hero: '¡Sea el protagonista de su historia, conecte con U4hero!',
    mensagemErroSenha: '¡Por favor, digite su contraseña!',
    esqueceuSuaSenha: '¿Olvidó su contraseña? ¡Haga clic aquí!',
    mensagemErroEmailSenha: '¡Ups! Parece que el email y/o la contraseña ingresados están incorrectos.',
    cadastroRealizadoComSucesso: '¡Su registro fue realizado con éxito!',
    erroAoSalvarAcessoRapido: 'Error al guardar acceso rápido',
    acessoRapido: 'Acceso Rápido',
    nomeOuApelido: 'Nombre o Apodo',
    mensagemErroNomeOuApelido: '¡Por favor, informe su nombre o apodo!',
    emailNaoExistente: 'Email no existente',
    emailEnviado: '¡Email enviado!',
    mensagemErroEmailSenhaInstrucoes: '¡Enviamos un email con las instrucciones para crear una nueva contraseña!',
    esqueceuSuaSenhaPergunta: '¿Olvidó su contraseña?',
    mensagemRecuperarSenhaInstrucoes: '¡No se preocupe! Por favor, ingrese su email registrado en U4hero y le enviaremos instrucciones para crear una nueva contraseña.',
    linkExpirado: 'Link Expirado',
    mensagemLinkExpirado: 'El tiempo para activar su registro ha expirado. Si aún desea completarlo, por favor, entre en contacto con el administrador de su escuela para generar un nuevo enlace de activación.',
    mensagemLinkExpiradoCadastro: 'El enlace para finalizar su registro ha expirado, parece que ya completó el registro anteriormente. Por favor, entre en contacto con nosotros si necesita ayuda para acceder a su cuenta.',
    entendi: 'Entendido',
    mensagemCadastroQuasePronto: '¡Está a punto de empezar su jornada con U4hero!',
    mensagemErroNome: '¡Por favor, digite su nombre!',
    placeholderNome: 'Digite su nombre aquí',
    senhaRedefinida: '¡Contraseña Redefinida!',
    mensagemSenhaRedefinida: '¡Excelente! Su contraseña fue redefinida con éxito. Ahora puede acceder a su cuenta con su nueva contraseña.',
    redefinaSuaSenha: '¡Redefina su contraseña!',
    mensagemRedefinirSenhaInstrucoes: 'Por favor, cree una nueva contraseña siguiendo las directrices proporcionadas abajo',
    permissaoPara: 'Permiso para',
    recursoExcluidoComSucesso: '¡Recurso excluido con éxito!',
    erroAoExcluirRecurso: 'Hubo un problema al excluir el recurso. Verifique su conexión e inténtelo de nuevo.',
    recurso: 'Recurso',
    temCertezaQueDesejaExcluirORecurso: '¿Tiene la certeza de que desea excluir el recurso?',
    placeholderNomeRecurso: 'Ingrese el nombre del recurso',
    placeholderDescricaoRecurso: 'Cree una breve descripción del recurso', 
    mensagemErroDescricao: 'Por favor, cree una descripción',
    alteracoesSalvas: 'Cambios guardados',
    instituicoes: 'Instituciones',
    ultimo7Dias: 'Últimos 7 días',
    ultimo15Dias: 'Últimos 15 días',
    ultimo30Dias: 'Últimos 30 días',
    ultimoAno: 'Último año',
    todoPeriodo: 'Todo período',
  },
});
